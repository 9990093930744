<footer class="main-footer"  id="footer">
  <div class="footer-top">
    <div class="container">
        
      <div class="row">
  
        <div class="col-lg-3 col-md-6 footer-contact">
          <h3>Nuestra<span> Ubicación</span></h3>
          <p>
            Col. San Benito, Calle La Mascota Edificio #533, San Salvador Centro, El Salvador
            <br/>
            <strong>Teléfono:</strong> +503 2522-2875<br>
            <strong>Email:</strong> info@nexxus-technology.com<br>
          </p>
        </div>
  
        <div class="col-lg-3 col-md-6 footer-links">
          <h4>Nexxus Technology</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a [routerLink]="['/']">Home</a></li>
            <li><i class="bx bx-chevron-right"></i> <a [routerLink]="['/services']">Servicios</a></li>
            <li><i class="bx bx-chevron-right"></i> <a [routerLink]="['/contact']">Contactenos</a></li>
            <li><i class="bx bx-chevron-right"></i> <a [routerLink]="['/terms-conditions']">Términos y condiciones</a></li>
            <li><i class="bx bx-chevron-right"></i> <a [routerLink]="['/privacy-policy']">Política de Privacidad</a></li>
            <!--<li><i class="bx bx-chevron-right"></i> <a href="#">Terms of service</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="#">Privacy policy</a></li>-->
          </ul>
        </div>
  
        <div class="col-lg-3 col-md-6 footer-links">
          <h4>Nuestros Services</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> Diseño web</li>
            <li><i class="bx bx-chevron-right"></i> Desarrollo web</li>
            <li><i class="bx bx-chevron-right"></i> Desarrollo de APP's</li>
            <li><i class="bx bx-chevron-right"></i> Auditorias</li>
            <!--<li><i class="bx bx-chevron-right"></i> <a href="!#">Product Management</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="!#">Marketing</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="!#">Graphic Design</a></li>-->
          </ul>
        </div>
  
        <div class="col-lg-3 col-md-6 footer-links">
          <h4>Nuestras redes sociales</h4>
          <p>¡Como podemos ayudar!</p>
          <div class="social-links mt-3">
            <!--<a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>-->
            <a target="_blank" href="https://www.facebook.com/nexxus.technology" class="facebook"><i class="bx bxl-facebook"></i></a>
            <!--<a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
            <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>-->
            <a target="_blank" href="https://www.linkedin.com/company/nexxus-technology" class="linkedin"><i class="bx bxl-linkedin"></i></a>
          </div>
        </div>
  
      </div>
    </div>
  </div>
  
  <div class="container py-4">
    <img src="https://connectamericas.com/sites/default/files/content-idb/verifiedbadge.png" />
    <br/>
</div>

<div class="container py-4">
  <div class="copyright">
    &copy; Copyright <strong><span>Nexxus Technology</span></strong>
  </div>
  <div class="credits">
    Todos los derechos reservados
    <br/>
  </div>
</div>
</footer>
